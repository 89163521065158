.App {
  text-align: center;
}

.App-header {
  background-color: #36393f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-header time {
  display: block;
  text-align: left;
}

.container {
  width: 75%;
  max-width: 960px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 768px;
  }
}

@media (max-width: 960px) {
  .container {
    width: 100%;
    max-width: 960px;
    padding: 10%;
  }
}

h2 {
  margin-bottom: 0;
}
p {
  margin-top: 0;
}

.date-picker {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

input[type="datetime-local"] {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(32, 34, 37, 0.5);
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    min-height: 40px;
    position: relative;
    transition: border 0.15s ease 0s;
    box-sizing: border-box;
    outline: 0px !important;
    color: white;
    padding: 0 10px;
    width: auto;
    display: flex;
    flex-grow: 1;
}

input[type="datetime-local"]:hover {
    border-color: rgba(0, 0, 0, 0.6);
}

button {
    background-color: #6263f0;
    border: none;
    border-radius: 3px;
    height: 40px;
    padding: 0px 25px;
    margin: auto 10px;
    color: white;
    cursor: pointer;
    transition: background-color 0.15s ease 0s;
}

button:hover {
    background-color: #4f50c2;
}
button:active {
    background-color: #4244a3;
}